import React, { createContext, useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StatusCodes } from "http-status-codes";

import { api } from "../services/api";
import Alert from "../shared/Alert";

interface IUser {
  bairro: string;
  cep: string;
  cidade: string;
  cnpjCoordenador: string;
  codigo: number;
  compl: string;
  cpfcnpj: string;
  ddd: string;
  ddd2: string;
  ddd3: string;
  email: string;
  endereco: string;
  estado: string;
  fantasia: string;
  horarios: null;
  medicoSolicitante: string;
  nome: string;
  numero: string;
  pragma: string;
  principal: false;
  proTipoAgenda: string;
  redefinirSenha: false;
  telefone: string;
  telefone2: string;
  telefone3: string;
  tipoEndereco: string;
  tipoFone: string;
  tipoFone2: string;
  tipoFone3: string;
  unidadeMovel: false;
  usuEmail: string;
  usuNome: string;
  usuPerfil: string;
}

interface IAuthState {
  token: string;
  user: IUser;
}

interface ISignInCredentials {
  email?: string;
  password?: string;
}

interface IAuthContextData {
  token: string;
  user: IUser;
  signIn(credentials: ISignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): void;
}

const AuthContext = createContext<IAuthContextData>({} as IAuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();

  const [data, setData] = useState<IAuthState>(() => {
    const token = localStorage.getItem("@PortalPrestador:Token");
    const userJSON = localStorage.getItem("@PortalPrestador:User");
    if (token && userJSON) {
      const user = JSON.parse(userJSON) as IUser;
      api.defaults.headers.common["Authentication"] = token;
      api.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response.status === StatusCodes.UNAUTHORIZED) {
            signOut();
            Alert.fire({
              icon: "error",
              title: "Erro de autenticação",
              text: "Sua sessão expirou, faça login novamente.",
            });
          }
          return Promise.reject(error);
        }
      );
      return { token, user };
    }

    return {} as IAuthState;
  });

  const signOut = useCallback(() => {
    localStorage.removeItem("@PortalPrestador:Token");
    localStorage.removeItem("@PortalPrestador:User");
    delete api.defaults.headers.common["Authentication"];
    setData({} as IAuthState);
    navigate("/");
  }, [navigate]);

  const signIn = useCallback(
    async ({ email, password }: ISignInCredentials) => {
      try {
        if (email && password) {
          const {
            data: { token },
          } = await api.post<{ token: string }>(
            "/auth/Login",
            {},
            {
              headers: {
                jwtusername: email,
                jwtpassword: password,
                environment: "P",
              },
            }
          );
          api.defaults.headers.common["Authentication"] = token;
          var { data: user } = await api.post<IUser>(
            "/provider/authenticate",
            {}
          );
          setData({ token, user });
          localStorage.setItem("@PortalPrestador:Token", token);
          localStorage.setItem("@PortalPrestador:User", JSON.stringify(user));
          api.interceptors.response.use(
            (response) => {
              return response;
            },
            (error) => {
              if (error.response.status === StatusCodes.UNAUTHORIZED) {
                signOut();
                Alert.fire({
                  icon: "error",
                  title: "Erro de autenticação",
                  text: "Sua sessão expirou, faça login novamente.",
                });
              }
              return Promise.reject(error);
            }
          );
          return;
        }
      } catch (error) {}
      await Alert.fire({
        icon: "warning",
        title: "Aviso",
        text: "Falha no login. Verifique seus dados de acesso.",
      });
    },
    [signOut]
  );

  const updateUser = useCallback(
    (user: IUser) => {
      localStorage.setItem("@PortalPrestador:User", JSON.stringify(user));
      setData({
        token: data.token,
        user,
      });
    },
    [data.token]
  );

  return (
    <AuthContext.Provider
      value={{
        token: data.token,
        user: data.user,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): IAuthContextData {
  const context = useContext(AuthContext);
  return context;
}
