import { FormEvent, useCallback, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";
import BlockUi from "react-block-ui";
import { format } from "date-fns";

import { Page } from "../../components/Page";
import { api } from "../../services/api";

export interface IExameModel {
  codigo: number;
  nome: string;
}

export interface IAmbulatorioData {
  cpf: string;
  funcodigo: number;
  empcodigo: number;
  procodigo: number;
  qrCode: string;
  nome: string;
  providerNome: string;
  exames: IExameModel[];
}

function BeneficioCadastro(): JSX.Element {
  const attachmentInputRef = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<any[]>([]);
  const [date, setDate] = useState<Date | null>(null);
  const [descricao, setDescricao] = useState<string>("");
  const [exam, setExam] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);
  const { state } = useLocation();
  const { ambulatorio } = state as { ambulatorio: IAmbulatorioData };

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      setLoading(true);
      e.preventDefault();
      if (!date) {
        setLoading(false);
        return;
      }

      var reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = async () => {
        await api.post<IAmbulatorioData>("/medical/provider/qrCode", {
          procodigo: ambulatorio.procodigo,
          localizador: ambulatorio.qrCode,
          funcodigo: ambulatorio.funcodigo,
          empcodigo: ambulatorio.empcodigo,
          dataAgendamento: format(date, "dd-MM-yyyy"),
          descricao,
          exam,
          fileName: files[0].name,
          fileBase64: reader.result,
        });

        setLoading(false);
      };
      reader.onerror = function (error) {
        setLoading(false);
      };
    },
    [
      ambulatorio.empcodigo,
      ambulatorio.funcodigo,
      ambulatorio.procodigo,
      ambulatorio.qrCode,
      date,
      descricao,
      exam,
      files,
    ]
  );

  const handleAttachmentClick = useCallback(async (e) => {
    e.preventDefault();
    attachmentInputRef.current?.click();
  }, []);

  const handleAttachmentChange = useCallback(
    async (e) => {
      e.preventDefault();
      const file = e.target.files[0];
      setFiles([...files, file]);
      if (attachmentInputRef.current) {
        attachmentInputRef.current.value = "";
      }
    },
    [files]
  );

  const handleAttachmentRemove = useCallback(
    async (e) => {
      e.preventDefault();
      const index = e.target.dataset.index;
      const newFiles = files.filter((_, i) => i !== index);
      setFiles(newFiles);
    },
    [files]
  );

  return (
    <Page>
      <BlockUi
        tag="div"
        blocking={loading}
        loader={<></>}
        message="Salvando agendamento..."
        keepInView
      >
        <form id="formAmb" method="POST" onSubmit={handleSubmit}>
          <div className="card horizontal">
            <div className="card-content">
              <b>{ambulatorio.providerNome}</b>
              <br />
              <b>Nome: </b> {ambulatorio.nome}
              <br />
              <b>CPF: </b> {ambulatorio.cpf}
            </div>
          </div>
          <div className="card horizontal">
            <div className="card-content">
              <div className="col s12">
                <label>Data agendamento</label>
                <DatePicker
                  selected={date}
                  showPopperArrow={false}
                  onChange={(date) => setDate(date)}
                  locale={ptBR}
                  portalId="root-portal"
                  dateFormat="dd/MM/yyyy"
                  placeholderText="__/__/____"
                />
              </div>
              <div className="col s12">
                <label htmlFor="descrição">Observação (opcional)</label>
                <textarea
                  id="descrição"
                  className="materialize-textarea"
                  onChange={(e) => setDescricao(e.target.value)}
                ></textarea>
              </div>
              <div className="col s12">
                <div>Exames</div>
                <ul className="text-left collection">
                  {ambulatorio.exames.map((exame) => (
                    <li key={exame.codigo} className="collection-item">
                      <input
                        required
                        type="radio"
                        className="radItem"
                        value={exame.codigo}
                        id={`exame_${exame.codigo}`}
                        onChange={(e) => setExam(e.target.value)}
                      />
                      <label htmlFor={`exame_${exame.codigo}`}>
                        {exame.nome}
                      </label>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="col s12">
                <button
                  className="link no-decoration blue-text"
                  onClick={handleAttachmentClick}
                >
                  Anexar
                </button>
                <input
                  type="file"
                  style={{ display: "none" }}
                  ref={attachmentInputRef}
                  onChange={handleAttachmentChange}
                />
                <div id="files" className="files">
                  {files.map((file) => (
                    <div className="chip">
                      {file.name}
                      <i
                        className="material-icons close"
                        onClick={handleAttachmentRemove}
                      >
                        close
                      </i>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="fixed-action-btn">
            <div id="alert-text">Selecione um exame</div>
            <button
              disabled
              className="btn waves-effect waves-light"
              type="submit"
              id="btn_submit"
              name="action"
            >
              Avançar
              <i className="material-icons right">send</i>
            </button>
          </div>
        </form>
      </BlockUi>
    </Page>
  );
}

export { BeneficioCadastro };
