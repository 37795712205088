import { FormEvent, useCallback, useEffect, useState } from "react";
import M from "materialize-css";
import { Link } from "react-router-dom";

import { useAuth } from "../../hooks/auth";
import styles from "./styles.module.scss";

function Login(): JSX.Element {
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();

  const { signIn } = useAuth();

  useEffect(() => {
    M.updateTextFields();
  }, []);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      await signIn({ email, password });
    },
    [email, password, signIn]
  );

  return (
    <div className={`${styles.container} container`}>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="s12 m7 left-align" style={{ maxWidth: "300px" }}>
            <div className="card">
              <div
                className="card-content"
                id="fase1"
                style={{ height: "240px" }}
              >
                <span className="card-title">Agenda Gerale</span>
                <div className="row">
                  <div className="input-field col s12 left-align">
                    <input
                      className="validate"
                      type="email"
                      name="usuario"
                      id="email"
                      onChange={(e) => setEmail(e.target.value)}
                      value={email}
                      placeholder="E-mail"
                    />
                    <label htmlFor="email">E-mail</label>
                  </div>
                  <div className="input-field col s12 left-align">
                    <input
                      className="validate"
                      type="password"
                      name="senha"
                      id="password"
                      onChange={(e) => setPassword(e.target.value)}
                      value={password}
                      placeholder="Senha"
                    />
                    <label htmlFor="password">Senha</label>
                  </div>
                </div>
              </div>
              <div className="card-action">
                <input
                  type="submit"
                  name="btn_login"
                  id="loginPrimeiraFase"
                  className="btn"
                  value="Próximo"
                />
                <div className="input-field left-align">
                  <Link className="blue-text" to="/recuperar-senha">
                    Recuperar senha
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export { Login };
