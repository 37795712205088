import { FormEvent, useCallback, useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import M from "materialize-css";
import { StatusCodes } from "http-status-codes";

import Alert from "../../shared/Alert";
import { api } from "../../services/api";
import styles from "./styles.module.scss";

function AlterarSenha(): JSX.Element {
  const [senhaAtual, setSenhaAtual] = useState<string>();
  const [senhaNova, setSenhaNova] = useState<string>();
  const [confirmarSenhaNova, setConfirmarSenhaNova] = useState<string>();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    document.body.classList.add("login-page");
    M.updateTextFields();
    M.AutoInit();
  }, []);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (senhaNova !== confirmarSenhaNova) {
        await Alert.fire({
          icon: "warning",
          title: "Erro",
          text: "A nova senha não confere com a confirmação",
        });
        return;
      }

      const codigoEmBase64 = searchParams.get("c");
      const codigo = codigoEmBase64 ? window.atob(codigoEmBase64) : "";
      const codigoUsuario = codigo.split("-")[0];

      const { status } = await api.post<boolean>("/provider/changePassword", {
        tipo: searchParams.get("tp"),
        senhaNova,
        senhaAtual,
        usuCodigo: codigoUsuario,
        login: searchParams.get("l"),
      });

      if (status === StatusCodes.OK) {
        await Alert.fire({
          title: "Sucesso",
          text: "Senha alterada com sucesso",
        });
      }
    },
    [confirmarSenhaNova, searchParams, senhaAtual, senhaNova]
  );

  return (
    <div className={`${styles.container} container`}>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="s12 m7 left-align" style={{ maxWidth: "300px" }}>
            <div className="card">
              <div className="card-content" id="fase1">
                <span className="card-title">Cadastro de Senha</span>
                <div className="row">
                  <div className="primeirafase fase">
                    <div className="row col s12" style={{ marginTop: "1em" }}>
                      <p>Cadastre uma nova senha de acesso.</p>
                    </div>
                    {searchParams.get("tp") === "T" && (
                      <div className="input-field col s12 left-align">
                        <input
                          className="validade"
                          type="password"
                          id="senhaatual"
                          onChange={(e) => setSenhaAtual(e.target.value)}
                          required
                        />
                        <label htmlFor="senhaatual">Senha Atual</label>
                      </div>
                    )}
                    <div className="input-field col s12 left-align">
                      <input
                        type="password"
                        id="senhanova"
                        onChange={(e) => setSenhaNova(e.target.value)}
                        required
                      />
                      <label htmlFor="senhanova">Senha Nova</label>
                    </div>
                    <div className="input-field col s12 left-align">
                      <input
                        type="password"
                        id="confirmarsenhanova"
                        onChange={(e) => setConfirmarSenhaNova(e.target.value)}
                        required
                      />
                      <label htmlFor="confirmarsenhanova">Repita a Senha</label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card-action">
                <button
                  className="btn waves-effect waves-light"
                  type="submit"
                  name="action"
                  id="loginPrimeiraFase"
                >
                  Próximo
                </button>
                <Link
                  className="blue-text ml"
                  to="/"
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export { AlterarSenha };
