import Swal, { SweetAlertOptions } from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default class Alert {
  static _MySwal = withReactContent(Swal);

  static _defaultAlertOptions = {
    confirmButtonText: "Ok",
    confirmButtonColor: "#2bbbad",
  };

  static _assign(options: SweetAlertOptions): SweetAlertOptions {
    return { ...this._defaultAlertOptions, ...options };
  }

  static async fire(options: SweetAlertOptions): Promise<void> {
    if (!options.icon) options.icon = "success";

    const alertOptions = this._assign(options);
    await this._MySwal.fire(alertOptions);
  }
}
