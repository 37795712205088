import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";
import { StatusCodes } from "http-status-codes";

import { api } from "../../../services/api";
import { useAuth } from "../../../hooks/auth";
import Alert from "../../../shared/Alert";

interface IAdicionarDisponibilidadeProps {
  searchAvailability: () => void;
}

function AdicionarDisponibilidade({
  searchAvailability,
}: IAdicionarDisponibilidadeProps): JSX.Element {
  const [diaSemana, setDiaSemana] = useState<string>("");
  const [horaInicial, setHoraInicial] = useState<string>("");
  const [horaFinal, setHoraFinal] = useState<string>("");
  const [descricao, setDescricao] = useState<string>("");
  const [intervalo, setIntervalo] = useState<string>("");
  const [qtdeAgenda, setQtdeAgenda] = useState<string>("");

  const { user } = useAuth();

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    M.AutoInit();
    M.updateTextFields();
  }, []);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const { status, data } = await api.post<string>(
        "/provider/availability",
        {
          diaSemana,
          horaInicial,
          horaFinal,
          descricao,
          qtdeAgenda,
        }
      );

      if (status === StatusCodes.OK) {
        await Alert.fire({
          title: "Sucesso",
          text: data,
        });
        if (modalRef.current) {
          M.Modal.getInstance(modalRef.current).close();
          searchAvailability();
          setDiaSemana("");
          setHoraInicial("");
          setHoraFinal("");
          setDescricao("");
          setIntervalo("");
          setQtdeAgenda("");
        }
      }
    },
    [
      diaSemana,
      horaInicial,
      horaFinal,
      descricao,
      qtdeAgenda,
      searchAvailability,
    ]
  );

  return (
    <div id="modal-adicionardisponibilidade" className="modal" ref={modalRef}>
      <form id="form_configuracao" onSubmit={handleSubmit}>
        <div className="modal-content">
          <h4>Configuração da agenda</h4>

          <input type="hidden" name="padCodigo" value="@(Model?.padCodigo)" />
          <div className="row col s12 m-bottom-0">
            <div className="col s3">
              <label htmlFor="agenda">
                Dia <span className="required"></span>
              </label>
              <select
                className="form-control browser-default"
                data-val="true"
                data-val-number="The field diaSemana must be a number."
                data-val-required="O campo diaSemana é obrigatório."
                id="diaSemana"
                name="diaSemana"
                onChange={(e) => setDiaSemana(e.target.value)}
                defaultValue={diaSemana}
                required
              >
                <option value=""></option>
                <option value="1">Domingo</option>
                <option value="2">Segunda-Feira</option>
                <option value="3">Terça-Feira</option>
                <option value="4">Quarta-Feira</option>
                <option value="5">Quinta-Feira</option>
                <option value="6">Sexta-Feira</option>
                <option value="7">Sábado</option>
              </select>
            </div>
            {user.proTipoAgenda === "P" && (
              <div className="col s3">
                <label htmlFor="agenda">
                  Período <span className="required"></span>
                </label>
                <select
                  className="form-control browser-default"
                  id="descricao"
                  name="descricao"
                  onChange={(e) => setDescricao(e.target.value)}
                  defaultValue={descricao}
                  required
                >
                  <option value=""></option>
                  <option value="Manhã">Manhã</option>
                  <option value="Tarde">Tarde</option>
                </select>
              </div>
            )}
            <div className="col s2">
              <label htmlFor="agenda">
                Hora inicial <span className="required"></span>
              </label>
              <InputMask
                mask="99:99"
                onChange={(e) => setHoraInicial(e.target.value)}
                value={horaInicial}
                placeholder="__:__"
                className="validade"
              />
            </div>
            <div className="col s2">
              <label htmlFor="agenda">
                Hora Final <span className="required"></span>
              </label>
              <InputMask
                mask="99:99"
                onChange={(e) => setHoraFinal(e.target.value)}
                value={horaFinal}
                placeholder="__:__"
                className="validade"
              />
            </div>
            {user.proTipoAgenda === "H" && (
              <div className="col s3">
                <label htmlFor="agenda">
                  Intervalo <span className="required"></span>
                </label>
                <InputMask
                  mask="99"
                  onChange={(e) => setIntervalo(e.target.value)}
                  value={intervalo}
                  placeholder="__"
                  className="validade"
                />
              </div>
            )}
            {user.proTipoAgenda === "P" && (
              <div className="col s2">
                <label htmlFor="agenda">
                  Capacidade <span className="required"></span>
                </label>
                <input
                  type="number"
                  onChange={(e) => setQtdeAgenda(e.target.value)}
                  value={qtdeAgenda}
                  className="validade"
                />
              </div>
            )}
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="modal-footer">
          <div className="col s12">
            <button
              className="btn waves-effect waves-light"
              type="submit"
              name="action"
            >
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export { AdicionarDisponibilidade };
