import { useCallback } from "react";
import { NavLink } from "react-router-dom";
import { useAuth } from "../hooks/auth";

function Menu(): JSX.Element {
  const handleSelectedLink = useCallback(
    ({ isActive }: { isActive: boolean }) => {
      let classes = "grey-text text-darken-1";

      if (isActive) {
        classes += " active";
      }

      return classes;
    },
    []
  );

  const user = useAuth();

  const codigoEmBase64 = window.btoa(user.user.codigo.toString());

  return (
    <div className="col s12">
      <div className="card lighten-4">
        <div className="card-content">
          <div className="card-title">
            <span className="card-title activator grey-text text-darken-4">
              Menu
            </span>
          </div>
          <ul>
            <li>
              .{" "}
              <NavLink
                to="/beneficioambulatorial"
                className={handleSelectedLink}
              >
                Benefício Ambulatorial
              </NavLink>
            </li>
            <li>
              .{" "}
              <NavLink
                to="/configuracaodisponibilidade"
                className={handleSelectedLink}
              >
                Configurar disponibilidade
              </NavLink>
            </li>
            <li>
              .{" "}
              <NavLink
                to="/configuracaoindisponibilidade"
                className={handleSelectedLink}
              >
                Configurar indisponibilidade
              </NavLink>
            </li>
            <li>
              .{" "}
              <NavLink
                to={`/alterarsenha?c=${codigoEmBase64}&tp=T&l=${encodeURIComponent(
                  user.user.usuEmail
                )}`}
                className={handleSelectedLink}
              >
                Alterar Senha
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export { Menu };
