import { useCallback, useEffect, useRef, useState } from "react";
import M from "materialize-css";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";

import styles from "./styles.module.scss";

function RecuperarSenha(): JSX.Element {
  const [email, setEmail] = useState<string>();
  const [fase, setFase] = useState<number>(1);
  const captchaRef = useRef(null);

  useEffect(() => {
    M.updateTextFields();
  }, []);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!captchaRef.current) {
        return;
      }

      if (fase === 1) {
        var recaptcha = captchaRef.current as ReCAPTCHA;
        if (email && recaptcha.getValue() !== null) {
          setFase(2);
        }
      } else if (fase === 2) {
      }
    },
    [email, fase]
  );

  return (
    <div className={`${styles.container} container`}>
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="s12 m7 left-align" style={{ maxWidth: "340px" }}>
            <div className="card">
              <div className="card-content" id="fase1">
                <span className="card-title">Recuperar Senha</span>
                <div className="row">
                  {fase === 1 && (
                    <div className="primeirafase fase">
                      <div className="row col s12" style={{ marginTop: "1em" }}>
                        <p>
                          Podemos ajudá-lo a redefinir sua senha. Primeiro,
                          insira seu usuário de acesso e siga as instruções a
                          seguir.
                        </p>
                      </div>

                      <div className="input-field col s12 left-align">
                        <input
                          className="validate"
                          type="email"
                          name="usuario"
                          id="email"
                          onChange={(e) => setEmail(e.target.value)}
                          value={email}
                          placeholder="E-mail"
                          required
                        />
                        <label htmlFor="email">E-mail</label>
                      </div>
                      <div className="input-field col s12 left-align">
                        <ReCAPTCHA
                          ref={captchaRef}
                          sitekey={process.env.REACT_APP_RECAPTCHA_KEY ?? ""}
                        />
                      </div>
                    </div>
                  )}
                  {fase === 2 && (
                    <div className="segundafase fase">
                      <div className="row input-field col s12">
                        <p>
                          Foi enviado um código para seu email. Insira-o abaixo
                          para prosseguir com a alteração da senha.
                        </p>
                      </div>
                      <div className="input-field col s12 left-align">
                        <span>Código</span>
                        <textarea
                          className="validate"
                          name="codigo"
                          id="codigo"
                          required
                          style={{ height: "100px" }}
                        ></textarea>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-action">
                <button
                  className="btn waves-effect waves-light"
                  type="submit"
                  name="action"
                  id="loginPrimeiraFase"
                >
                  Próximo
                </button>
                <Link
                  className="blue-text ml"
                  to="/"
                  style={{ marginLeft: "10px" }}
                >
                  Cancelar
                </Link>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

export { RecuperarSenha };
