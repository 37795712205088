import axios, { AxiosError } from "axios";
import { StatusCodes } from "http-status-codes";
import NProgress from "nprogress";

import Alert from "../shared/Alert";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

api.interceptors.request.use(
  function (config) {
    NProgress.start();
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function (response) {
    NProgress.done();
    return response;
  },
  function (error) {
    NProgress.done();
    const axiosError = error as AxiosError;
    if (
      axiosError.isAxiosError &&
      axiosError.response?.status !== StatusCodes.UNAUTHORIZED
    ) {
      Alert.fire({
        icon: "warning",
        title: "Aviso",
        text: axiosError.response?.data?.mensagem,
      });
    }
    return Promise.reject(error);
  }
);

export { api };
