import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./hooks/auth";
import { Routes } from "./routes";

import "./styles/global.scss";

// class ErrorBoundary extends Component<any, any> {
//   constructor(props: any) {
//     super(props);
//     this.state = { hasError: false };
//   }

//   static getDerivedStateFromError(error: any) {
//     console.log("error", error.message);
//     return { hasError: true, message: error.message };
//   }
//   // componentDidCatch(error, errorInfo) {}
//   render() {
//     if (this.state.hasError) {
//       return <h1>Algo deu errado</h1>;
//     }
//     return this.props.children;
//   }
// }

function App(): JSX.Element {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Routes />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
