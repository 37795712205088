import { FormEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StatusCodes } from "http-status-codes";

import { Page } from "../../components/Page";
import { api } from "../../services/api";

export interface IExameModel {
  codigo: number;
  nome: string;
}

export interface IAmbulatorioData {
  cpf: string;
  funcodigo: number;
  empcodigo: number;
  procodigo: number;
  qrCode: string;
  nome: string;
  providerNome: string;
  exames: IExameModel[];
}

function BeneficioAmbulatorio(): JSX.Element {
  const [codigo, setCodigo] = useState<string>("");

  const navigate = useNavigate();

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      const { data, status } = await api.get<IAmbulatorioData>(
        "/medical/provider/qrCode",
        {
          params: {
            QrCode: codigo,
          },
        }
      );

      if (status === StatusCodes.OK) {
        navigate("/beneficioambulatorial/cadastro", {
          state: { ambulatorio: data },
        });
      }
    },
    [codigo, navigate]
  );

  return (
    <Page>
      <div className="card-panel">
        <form method="post" id="form_code" onSubmit={handleSubmit}>
          <label>Código localizador</label>
          <input
            type="text"
            required
            maxLength={50}
            autoComplete="off"
            style={{ textTransform: "uppercase" }}
            onChange={(e) => setCodigo(e.target.value)}
          />
          <input type="submit" value="Validar" className="btn" />
        </form>
      </div>
    </Page>
  );
}

export { BeneficioAmbulatorio };
