import { useAuth } from "../hooks/auth";

const Header = () => {
  const { user, signOut } = useAuth();

  return (
    <div className="navbar-fixed">
      <nav className="cyan darken-1">
        <div className="nav-wrapper row">
          <div className="header-title col s12 m12">
            <span className="chapter-title">
              <b>Bem-Vindo, {user.usuNome}</b>,
            </span>
            &nbsp;
            <button className="link" onClick={() => signOut()}>
              sair
            </button>
          </div>
        </div>
      </nav>
    </div>
  );
};

export { Header };
