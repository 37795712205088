import { useCallback, useEffect, useState } from "react";
import M from "materialize-css";
import { StatusCodes } from "http-status-codes";

import { Page } from "../../components/Page";
import { useAuth } from "../../hooks/auth";
import { api } from "../../services/api";
import { AdicionarDisponibilidade } from "./Modal/AdicionarDisponibilidade";
import Alert from "../../shared/Alert";

interface IProviderAvailabilityAPI {
  proCodigo: number;
  padCodigo: number;
  diaSemana: string;
  diaSemanaExtenso: string;
  horaInicial: string;
  horaFinal: string;
  intervalo: number;
  qtdeAgenda: number;
  descricao: string;
}

function ConfigurarDisponibilidade(): JSX.Element {
  const [availability, setAvailability] = useState<IProviderAvailabilityAPI[]>(
    []
  );
  const { user } = useAuth();

  useEffect(() => {
    M.AutoInit();
  }, []);

  const searchAvailability = useCallback(async () => {
    const { data } = await api.get<IProviderAvailabilityAPI[]>(
      "/provider/availability"
    );
    setAvailability(data);
  }, []);

  useEffect(() => {
    searchAvailability();
  }, [searchAvailability]);

  const proTipoAgenda = async (event: any) => {
    try {
      await api.post("/provider/provider", {
        PROTIPOAGENDA: event.target.value,
      });
    } catch (error: any) {
      Alert.fire({
        icon: "error",
        text: error.response?.data?.mensagem,
      });
    }
  };

  const handleDelete = useCallback(
    async (id: number) => {
      if (window.confirm("Confirma exclusão ?")) {
        const { status, data } = await api.post<string>(
          `/provider/deleteAvailability`,
          {
            padCodigo: id,
          }
        );

        if (status === StatusCodes.OK) {
          await Alert.fire({ title: "Sucesso", text: data });
          searchAvailability();
        }
      }
    },
    [searchAvailability]
  );

  return (
    <Page>
      <div className="col s12">
        <h5>Configuração da agenda - Disponibilidade</h5>
      </div>

      <div className="col s12">
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <div className="col s6" id="tipo">
                <form id="form_tipo">
                  <span>
                    <input
                      name="tipo"
                      type="radio"
                      id="P"
                      className="radio"
                      value="P"
                      defaultChecked={user.proTipoAgenda === "P"}
                      onClick={proTipoAgenda}
                    />
                    <label htmlFor="P">Período</label>
                  </span>
                  <span style={{ marginLeft: "20px" }}>
                    <input
                      name="tipo"
                      type="radio"
                      id="H"
                      className="radio"
                      value="H"
                      defaultChecked={user.proTipoAgenda === "H"}
                      onClick={proTipoAgenda}
                    />
                    <label htmlFor="H">Horário</label>
                  </span>
                </form>
              </div>

              <button
                data-target="modal-adicionardisponibilidade"
                className="btn right modal-trigger"
              >
                Adicionar disponibilidade
              </button>

              <div id="gridAgenda" className="col s12">
                {availability.length === 0 ? (
                  <div>Carregando...</div>
                ) : (
                  <table className="table table-striped grid-table">
                    <thead>
                      <tr>
                        <th
                          className="grid-header"
                          style={{ display: "none" }}
                        ></th>
                        <th className="grid-header">
                          <div className="grid-header-title">Dia da semana</div>
                        </th>
                        <th className="grid-header">
                          <div className="grid-header-title">Hora inicial</div>
                        </th>
                        <th className="grid-header">
                          <div className="grid-header-title">Hora final</div>
                        </th>
                        <th className="grid-header">
                          <div className="grid-header-title">Período</div>
                        </th>
                        <th className="grid-header">
                          <div className="grid-header-title">Capacidade</div>
                        </th>
                        <th className="grid-header"></th>
                      </tr>
                    </thead>
                    <tbody>
                      {availability.map((availability) => (
                        <tr className="grid-row ">
                          <td
                            className="grid-cell"
                            data-name="padCodigo"
                            style={{ display: "none" }}
                          >
                            {availability.padCodigo}
                          </td>
                          <td
                            className="grid-cell"
                            data-name="diaSemanaExtenso"
                          >
                            {availability.diaSemanaExtenso}
                          </td>
                          <td className="grid-cell" data-name="horaInicial">
                            {availability.horaInicial}
                          </td>
                          <td className="grid-cell" data-name="horaFinal">
                            {availability.horaFinal}
                          </td>
                          <td className="grid-cell" data-name="descricao">
                            {availability.descricao}
                          </td>
                          <td className="grid-cell" data-name="qtdeAgenda">
                            {availability.qtdeAgenda}
                          </td>
                          <td className="grid-cell" data-name="proCodigo">
                            <button
                              onClick={() =>
                                handleDelete(availability.padCodigo)
                              }
                              className="editGrid btnExcluir link no-decoration"
                              style={{ color: "#7D0000" }}
                            >
                              Excluir
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdicionarDisponibilidade searchAvailability={searchAvailability} />
    </Page>
  );
}

export { ConfigurarDisponibilidade };
