/* eslint-disable no-useless-escape */
import { Fragment, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { addHours, format, isValid } from "date-fns";
import ptBR from "date-fns/locale/pt-BR";

import { api } from "../../services/api";
import { parseISO } from "date-fns/esm";
import { Page } from "../../components/Page";
import { Atendimento } from "./Modal/Atendimento";

import "react-datepicker/dist/react-datepicker.css";

interface IAttendencesDateAPI {
  ateData: string;
}

export interface IAttandencesAPI {
  ateCodigo: number;
  ateCodigoCrypt: string;
  ateData: string;
  ateDataAtendido: string;
  ateDataCancelado: string;
  ateHora: string;
  ateStatus: string;
  funcionario: {
    fPlanId: number;
    fPlanLaboralCNPJ: string;
    fPlanLaboralSigla: string;
    fPlanNome: string;
    fPlanPatronalCNPJ: string;
    fPlanPatronalSigla: string;
    fPlanSegmento: string;
    funAdmissao: string;
    funAfastado: false;
    funCPF: string;
    funCTPS: string;
    funCodigo: number;
    funDDD: string;
    funDemissao: string;
    funEmail: string;
    funEstCivil: string;
    funMatricula: string;
    funNascimento: string;
    funNome: string;
    funPISNIT: string;
    funRG: string;
    funSexo: string;
    funTelefone: string;
    funTipoFone: string;
    funcao: {
      cgoCodigo: number;
      cgoNome: string;
      fcoCBO: string;
      fcoCodigo: number;
      fcoNome: string;
      gheCodigo: number;
      gheNome: string;
    };
    setor: {
      anaCodigo: number;
      setCodigo: number;
      setNome: string;
    };
  }[];
  outroServico: {
    codigo: number;
    grsCodigo: number;
    habilitado: boolean;
    mudarFuncao: boolean;
    nome: string;
    observacao: string;
    sigla: string;
    somenteContato: boolean;
    tipo: string;
    valor: number;
    valorPrestador: number;
  }[];
  prestador: {
    id: number;
  };
  tipoExame: {
    codigo: number;
    descricao: string;
    mudarFuncao: boolean;
    tipo: string;
  };
  tomador: {
    bairro: string;
    cep: string;
    cidade: string;
    cnpj: string;
    complemento: string;
    email: string;
    endereco: string;
    estado: string;
    fantasia: string;
    numero: string;
    razaoSocial: string;
    telefone: string;
  };
  totalAnexo: 0;
}

function Home(): JSX.Element {
  const [date, setDate] = useState<Date | null>(new Date());
  const [attendencesDates, setAttendencesDates] = useState<Date[]>([]);
  const [attendences, setAttendences] = useState<IAttandencesAPI[]>([]);
  const [attendenceSelected, setAttendenceSelected] = useState<IAttandencesAPI | null>(null);
  const monthChange = (date: Date) => {
    async function loadAttendencesDates(): Promise<void> {
      const { data } = await api.get<IAttendencesDateAPI[]>(
        "/attendance/attendance",
        {
          params: {
            mes: date.getMonth() + 1,
            ano: date?.getFullYear(),
          },
        }
      );

      setAttendencesDates(
        data.map((date) => addHours(new Date(date.ateData), 3))
      );
    }
    loadAttendencesDates();
  }

  useEffect(() => {
    monthChange(new Date());
  }, []);

  const loadAttencedences = async () => {
    const { data } = await api.get<string>(`/attendance/attendance`, {
      params: {
        AteData: date?.toISOString(),
      },
    });
    try {
      if (typeof data === "string") {
        const barcodestring = data.replace(
          new RegExp(
            String.fromCharCode(9).replace(/[-\/\\^$*+?.()|[\]{}]/g, "\\$&"),
            "g"
          ),
          ""
        );
        setAttendences(JSON.parse(barcodestring));
      } else {
        setAttendences(data);
      }
    } catch (err: any) {
      console.log("err", err.message);
    }
  };

  useEffect(() => {
    loadAttencedences();
  }, [date]);

  return (
    <Page
      backComponent={
        <div className="col s12">
          <div className="card lighten-4">
            <div className="card-content" style={{ padding: "5px 0 0 0" }}>
              <DatePicker
                selected={date}
                onMonthChange={(date) => monthChange(date)}
                onChange={(date) => {
                  if (date) {
                    setDate(new Date(date.toDateString()));
                  }
                }}
                locale={ptBR}
                highlightDates={attendencesDates}
                inline
              />
            </div>
          </div>
        </div>
      }
    >
      <div className="col s12 m12">
        <span id="dataSelecionada">
          {date ? format(date, "dd/MM/yyyy") : null}
        </span>
      </div>
      <div id="list_eventos">
        <div className="row">
          <div className="col s12 m12">
            {attendences.length === 0 && (
              <span>Sem resultados para esse dia</span>
            )}
            {
              attendences
                .map((attendence) => (
                  <Fragment key={attendence.ateCodigo}>
                    {
                      attendence
                        .funcionario
                        .map((funcionario) => (
                          <div key={funcionario.funCodigo} className="card horizontal">
                            <div className="card-stacked">
                              <div className="card-content">
                                <div className="col sm-4">{attendence.ateHora}</div>
                                <div className="col sm-4">
                                  <b>Empresa: </b> {attendence.tomador.cnpj} -{" "}
                                  {attendence.tomador.fantasia} <br />
                                  <b>Trabalhador: </b> {funcionario.funNome} -{" "}
                                  {funcionario.funcao.fcoNome} - {funcionario.funCPF}{" "}
                                  <br />
                                  <b>Tipo de exame: </b>{" "}
                                  {attendence.tipoExame.descricao} <br />
                                  {attendence.outroServico.length > 0 && (
                                    <>
                                      <b>Exames: </b>
                                      {attendence?.outroServico?.map(
                                        (outroServico, index, array) => {
                                          let content = outroServico?.nome;
                                          if (array.length > index + 1) content += ", ";
                                          return <span key={index}>{content}</span>;
                                        }
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                              <div className="card-action right-align">
                                {attendence.ateStatus === "C" &&
                                  attendence.ateDataCancelado && (
                                    <>
                                      <span>
                                        Cancelado em:{" "}
                                        {
                                          isValid(attendence.ateDataCancelado)
                                            ? parseISO(
                                              attendence.ateDataCancelado
                                            ).toLocaleDateString()
                                            : ""
                                        }
                                      </span>
                                      <i className="fa fa-ban" aria-hidden="true"></i>
                                    </>
                                  )}
                                {(attendence.ateStatus === "A" ||
                                  attendence.ateStatus === "S") && (
                                    <>
                                      <span>
                                        Atendimento em:{" "}
                                        {
                                          isValid(attendence.ateDataAtendido)
                                            ? parseISO(attendence.ateDataAtendido)
                                              .toLocaleDateString()
                                            : ""
                                        }{" "}
                                        {attendence.ateHora}{" "}
                                      </span>
                                      <i className="fa fa-check" aria-hidden="true"></i>
                                      <br />
                                      <span>
                                        Arquivos anexados anteriormente: ({" "}
                                        {attendence.totalAnexo} )
                                      </span>
                                      <br />
                                      <button
                                        style={{ padding: 0, margin: 0 }}
                                        onClick={() => {
                                          setAttendenceSelected(attendence)
                                        }}
                                        className="blue-text link modal-trigger"
                                        data-target={`modal-agenda-${attendence.ateCodigo}`}
                                      >
                                        ANEXAR COMPROVANTE (ASO)
                                      </button>{" "}
                                      <i
                                        className="fa fa-paperclip"
                                        aria-hidden="true"
                                      ></i>
                                    </>
                                  )}
                                {attendence.ateStatus === "P" && (
                                  <>
                                    <span>Aguardando pagamento</span>
                                    <i
                                      className="fa fa-clock-o mgleft5"
                                      aria-hidden="true"
                                    ></i>
                                  </>
                                )}
                                {attendence.ateStatus === "L" && (
                                  <>
                                    <button
                                      onClick={() => {
                                        setAttendenceSelected(attendence)
                                      }}
                                      data-target={`modal-agenda-${attendence.ateCodigo}`}
                                      className="btn waves-effect waves-light modal-trigger"
                                    >
                                      Realizar Atendimento
                                      <i className="material-icons right">send</i>
                                    </button>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                        ))
                    }
                  </Fragment>
                ))
            }
          </div>
        </div>
      </div>
      <Atendimento
        id={attendenceSelected?.ateCodigo}
        agendaEvento={[attendenceSelected!]}
        loadAttencedences={loadAttencedences}
        afterClose={() => setAttendenceSelected(null)}
      />
    </Page>
  );
}

Home.defaultProps = {
  exibeCalendario: true,
};

export { Home };
