import { Link } from "react-router-dom";
import { Header } from "./Header";
import { Menu } from "./Menu";

interface IPageProps {
  backComponent?: React.ReactNode;
  children: React.ReactNode;
}

function Page({
  backComponent: BackComponent,
  children,
}: IPageProps): JSX.Element {
  return (
    <div>
      <Header />
      <div className="body-content">
        <div className="row">
          <div className="col l3 s12 m3 boxCalendario">
            {BackComponent ? (
              <>{BackComponent}</>
            ) : (
              <Link to="/" className="grey-text text-darken-1">
                &lt;&lt; Voltar
              </Link>
            )}

            <Menu />
          </div>
          <div className="col s7">{children}</div>
          <div className="root-portal"></div>
        </div>
      </div>
    </div>
  );
}

export { Page };
