import { FormEvent, useCallback, useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import ptBR from "date-fns/locale/pt-BR";
import { StatusCodes } from "http-status-codes";

import { api } from "../../../services/api";
import Alert from "../../../shared/Alert";

interface IAdicionarIndisponibilidadeProps {
  searchUnavailability: () => void;
}

function AdicionarIndisponibilidade({
  searchUnavailability,
}: IAdicionarIndisponibilidadeProps): JSX.Element {
  const [tipo, setTipo] = useState<string>("");
  const [date, setDate] = useState<Date | null>(null);
  const [horaInicial, setHoraInicial] = useState<string>("");
  const [horaFinal, setHoraFinal] = useState<string>("");

  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    M.AutoInit();
    M.updateTextFields();
  }, []);

  const handleSubmit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault();
      if (date) {
        const { status, data } = await api.post<string>(
          "/provider/unavailability",
          {
            tipo,
            data: `${date.toISOString().split("T")[0]}T00:00:00`,
            horaInicial,
            horaFinal,
          }
        );

        if (status === StatusCodes.OK) {
          await Alert.fire({
            title: "Sucesso",
            text: data,
          });

          if (modalRef.current) {
            M.Modal.getInstance(modalRef.current).close();
            searchUnavailability();
            setTipo("");
            setHoraInicial("");
            setHoraFinal("");
            setDate(null);
          }
        }
      }
    },
    [tipo, horaInicial, horaFinal, date, searchUnavailability]
  );

  return (
    <div id="modal-adicionarindisponibilidade" className="modal" ref={modalRef}>
      <form id="form_configuracao" onSubmit={handleSubmit}>
        <div className="modal-content">
          <h4>Configuração da agenda</h4>

          <div className="row col s12 m-bottom-0">
            <div className="col s3">
              <label htmlFor="agenda">
                Dia <span className="required"></span>
              </label>
              <select
                className="form-control browser-default"
                data-val="true"
                data-val-number="The field diaSemana must be a number."
                data-val-required="O campo diaSemana é obrigatório."
                id="diaSemana"
                name="diaSemana"
                onChange={(e) => setTipo(e.target.value)}
                required
              >
                <option value=""></option>
                <option value="1">Feriado</option>
                <option value="2">Outros</option>
              </select>
            </div>
            <div className="col s3">
              <label htmlFor="agenda">
                Data <span className="required"></span>
              </label>
              <DatePicker
                selected={date}
                showPopperArrow={false}
                onChange={(date) => setDate(date)}
                locale={ptBR}
                portalId="root-portal"
                dateFormat="dd/MM/yyyy"
                placeholderText="__/__/____"
              />
            </div>
            <div className="col s3">
              <label htmlFor="agenda">
                Hora inicial <span className="required"></span>
              </label>
              <InputMask
                mask="99:99"
                onChange={(e) => setHoraInicial(e.target.value)}
                value={horaInicial}
                placeholder="__:__"
              />
            </div>
            <div className="col s3">
              <label htmlFor="agenda">
                Hora Final <span className="required"></span>
              </label>
              <InputMask
                mask="99:99"
                onChange={(e) => setHoraFinal(e.target.value)}
                value={horaFinal}
                placeholder="__:__"
              />
            </div>
          </div>
          <div className="clearfix"></div>
        </div>
        <div className="modal-footer">
          <div className="col s12">
            <button
              className="btn waves-effect waves-light"
              type="submit"
              name="action"
            >
              Salvar
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export { AdicionarIndisponibilidade };
