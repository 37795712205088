import { differenceInMilliseconds, format } from "date-fns";
import { useCallback, useEffect, useState } from "react";
import { StatusCodes } from "http-status-codes";

import { Page } from "../../components/Page";
import { api } from "../../services/api";
import { AdicionarIndisponibilidade } from "./Modal/AdicionarIndisponibilidade";
import Alert from "../../shared/Alert";

interface IProviderUnavailabilityAPI {
  proCodigo: number;
  padCodigo: number;
  diaSemana: string;
  diaSemanaExtenso: string;
  horaInicial: string;
  horaFinal: string;
  intervalo: number;
  qtdeAgenda: number;
  tipo: string;
  tipoExtenso: string;
  indCodigo: number;
  data: string;
  descricao: string;
}

function ConfigurarIndisponibilidade(): JSX.Element {
  const [unavailability, setUnavailability] = useState<
    IProviderUnavailabilityAPI[]
  >([]);

  useEffect(() => {
    M.AutoInit();
  }, []);

  const searchUnavailability = useCallback(async () => {
    const { data } = await api.get<IProviderUnavailabilityAPI[]>(
      "/provider/unavailability"
    );

    setUnavailability(
      data.sort((a, b) =>
        differenceInMilliseconds(new Date(a.data), new Date(b.data))
      )
    );
  }, []);

  useEffect(() => {
    searchUnavailability();
  }, [searchUnavailability]);

  const handleDelete = useCallback(
    async (id: number) => {
      if (window.confirm("Confirma exclusão ?")) {
        const { status, data } = await api.post<string>(
          `/provider/deleteUnavailability`,
          {
            indCodigo: id,
          }
        );

        if (status === StatusCodes.OK) {
          await Alert.fire({
            title: "Sucesso",
            text: data,
          });
          searchUnavailability();
        }
      }
    },
    [searchUnavailability]
  );

  return (
    <Page>
      <div className="col s12">
        <h5>Configuração da agenda - Indisponibilidade</h5>
      </div>

      <div className="col s12">
        <div className="card horizontal">
          <div className="card-stacked">
            <div className="card-content">
              <button
                data-target="modal-adicionarindisponibilidade"
                className="btn right modal-trigger"
              >
                Adicionar indisponibilidade
              </button>
              <div id="gridAgenda" className="col s12">
                <table className="table table-striped grid-table">
                  <thead>
                    <tr>
                      <th
                        className="grid-header"
                        style={{ display: "none" }}
                      ></th>
                      <th className="grid-header">
                        <div className="grid-header-title">Tipo</div>
                      </th>
                      <th className="grid-header">
                        <div className="grid-header-title">Data</div>
                      </th>
                      <th className="grid-header">
                        <div className="grid-header-title">Hora inicial</div>
                      </th>
                      <th className="grid-header">
                        <div className="grid-header-title">Hora final</div>
                      </th>
                      <th className="grid-header"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {unavailability.map((unavailable) => {
                      return (
                        <tr key={unavailable.indCodigo} className="grid-row">
                          <td
                            className="grid-cell"
                            data-name="padCodigo"
                            style={{ display: "none" }}
                          >
                            {unavailable.padCodigo}
                          </td>
                          <td className="grid-cell" data-name="tipoExtenso">
                            {unavailable.tipoExtenso}
                          </td>
                          <td className="grid-cell" data-name="data">
                            {format(
                              new Date(unavailable.data.slice(0, -1)),
                              "dd/MM/yyyy"
                            )}
                          </td>
                          <td className="grid-cell" data-name="horaInicial">
                            {unavailable.horaInicial}
                          </td>
                          <td className="grid-cell" data-name="horaFinal">
                            {unavailable.horaFinal}
                          </td>
                          <td className="grid-cell" data-name="proCodigo">
                            <button
                              onClick={() =>
                                handleDelete(unavailable.indCodigo)
                              }
                              className="editGrid btnExcluir link no-decoration"
                              style={{ color: "#7D0000" }}
                            >
                              Excluir
                            </button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AdicionarIndisponibilidade searchUnavailability={searchUnavailability} />
    </Page>
  );
}

export { ConfigurarIndisponibilidade };
