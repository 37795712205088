import { Routes as Switch, Route } from "react-router-dom";

import { useAuth } from "../hooks/auth";
import { AlterarSenha } from "../pages/AlterarSenha";
import { BeneficioAmbulatorio } from "../pages/BeneficioAmbulatorial";
import { ConfigurarIndisponibilidade } from "../pages/ConfigurarIndisponibilidade";
import { ConfigurarDisponibilidade } from "../pages/ConfigurarDisponibilidade";
import { Home } from "../pages/Home";
import { Login } from "../pages/Login";
import { RecuperarSenha } from "../pages/RecuperarSenha";
import { BeneficioCadastro } from "../pages/BeneficioAmbulatorial/BeneficioCadastro";

function Routes(): JSX.Element {
  const { user } = useAuth();

  if (user) {
    document.body.classList.remove("login-page");
    return (
      <Switch>
        <Route
          path="/beneficioambulatorial/cadastro"
          element={<BeneficioCadastro />}
        />
        <Route
          path="/beneficioambulatorial"
          element={<BeneficioAmbulatorio />}
        />
        <Route
          path="/configuracaodisponibilidade"
          element={<ConfigurarDisponibilidade />}
        />
        <Route
          path="/configuracaoindisponibilidade"
          element={<ConfigurarIndisponibilidade />}
        />
        <Route path="/alterarsenha" element={<AlterarSenha />} />
        <Route path="/" element={<Home />} />
      </Switch>
    );
  }

  document.body.classList.add("login-page");

  return (
    <Switch>
      <Route path="/recuperar-senha" element={<RecuperarSenha />} />
      <Route path="/" element={<Login />} />
    </Switch>
  );
}

export { Routes };
